import styled from "styled-components";
import { useEffect, useState, lazy, Suspense, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Client } from "typesense";
import { DateTime } from "luxon";
import {
  SearchParams,
  SearchResponseHit,
} from "typesense/lib/Typesense/Documents";
import {
  Coordinates,
  getNode,
  getNodes,
  useHistoryContext,
  useLocationContext,
} from "../../Components";
import {
  AutocompleteAsync,
  Option as OptionType,
} from "../../melodies-source/Autocomplete";
import { SearchBox } from "./Components";
import { P } from "../../melodies-source/Text";

import { useUserActionTrackingContext } from "../../Components/UserActionTrackingContext";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Autocomplete = styled(AutocompleteAsync)`
  input {
    color: rgba(27, 0, 118, 0.91);
    font-size: 20px;
  }
  input::placeholder {
    font-size: 20px;
  }
`;

const BelowFold = lazy(() => import("./Components/BelowFold"));

const client = new Client({
  nodes: getNodes(process.env.REACT_APP_SEARCH_SERVER),
  nearestNode: getNode(process.env.REACT_APP_SEARCH_SERVER_NEAREST),
  apiKey: process.env.REACT_APP_TYPESENSE_KEY as string,
  logLevel: process.env.REACT_APP_ENV === "production" ? "silent" : "debug",
});
export interface MatchedEventDetail {
  address: string;
  artistName: string;
  displayedName?: string;
  id: string;
  image: string;
  startsAt: number;
  venue: string;
  distance_meters?: number;
  geo_distance_meters?: number;
  isNearby?: boolean;
  version?: number;
}
interface CheckNearbyEvents {
  allEvents: MatchedEventDetail[];
  nearbyEvents?: MatchedEventDetail[];
  hasMultipleNearbyEvents: boolean;
}

const checkForNearbyEvents = (
  hits: SearchResponseHit<MatchedEventDetail>[],
  searchDistanceMeters: number,
): CheckNearbyEvents => {
  //@ts-ignore
  const closest = hits[0]?.geo_distance_meters?.coordinates || 0;
  const allEvents = hits?.map((hit, idx) => {
    const document = hit.document;
    //@ts-ignore
    const distance = hit?.geo_distance_meters?.coordinates;
    return {
      ...document,
      distance_meters: distance,
      isNearby: idx === 0 ? true : distance <= searchDistanceMeters + closest,
    };
  });

  const nearbyEvents = allEvents?.filter(({ isNearby }) => isNearby);
  const hasMultipleNearbyEvents = nearbyEvents?.length > 1;

  return { allEvents, nearbyEvents, hasMultipleNearbyEvents };
};

const useTypingText = (strings: string[], min = 50, max = 150) => {
  const [state, setState] = useState<{
    display: string;
    index: number;
    erasing: boolean;
  }>({ display: "", index: 0, erasing: false });

  useEffect(() => {
    let cancel = false;
    let to: any;
    const handleTyping = () => {
      if (cancel) {
        return;
      }
      setState(({ display, index, erasing }) => {
        let next = {
          display,
          index,
          erasing,
        };
        if (erasing) {
          if (display === "") {
            next.erasing = false;
            next.index = (index + 1) % strings.length;
          } else {
            next.display = display.substring(0, display.length - 1);
          }
        } else {
          if (display === strings[index]) {
            next.erasing = true;
          } else {
            next.display = strings[index].substring(0, display.length + 1);
          }
        }
        return next;
      });
      to = setTimeout(
        handleTyping,
        state.erasing ? min : Math.random() * (max - min) + min,
      );
    };
    handleTyping();
    return () => {
      if (to) {
        clearTimeout(to);
      }
      cancel = true;
    };
  }, [strings, min, max]);
  return state.display;
};

export const Landing = () => {
  const {
    hasLocationError,
    locationErrorMessage,
    handleLocationRequest,
    location,
    ipLocation,
    locationLoading,
    allowLocationSearch,
    setAllowLocationSearch,
  } = useLocationContext();
  const { isFirstRoute } = useHistoryContext();
  const navigate = useNavigate();
  const [searchButtonClicked, setSearchButtonClicked] =
    useState<boolean>(false);
  const [text, setText] = useState<string | null>(null);
  const [preloadedEvents, setPreloadedEvents] = useState<MatchedEventDetail[]>(
    [],
  );
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const searchDistanceMeters = 200;

  const { track } = useUserActionTrackingContext();
  const { i18n, t } = useTranslation();
  const typingStrings = useMemo(
    () => [t("Search by Artist...."), t("Search by Venue....")],
    [],
  );
  const placeholderText = useTypingText(typingStrings);

  const queryEvents = async (
    queryParams: SearchParams,
    radiusLimitCoords?: Coordinates,
  ) => {
    let filter_by = `endedAt:=0 && deletedAt:=0`.concat(
      queryParams?.filter_by ||
        ` && startsAt:<=${DateTime.utc()
          .plus({ hours: 12 })
          .toMillis()} && startsAt:>=${DateTime.utc()
          .minus({ hours: 12 })
          .toMillis()}`,
    );
    if (radiusLimitCoords) {
      filter_by = filter_by.concat(
        ` && coordinates:(${radiusLimitCoords?.latitude}, ${radiusLimitCoords?.longitude}, 200 mi)`,
      );
    }
    try {
      const result = await client
        .collections<MatchedEventDetail>("setlive_events")
        .documents()
        .search({
          limit_hits: 4,
          page: 1,
          per_page: 4,
          ...queryParams,
          filter_by,
        });

      if (result?.hits?.length) {
        return result.hits;
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Redirect to found show(s)
  useEffect(() => {
    if (location && allowLocationSearch && !hasLocationError && isFirstRoute) {
      navigateToNearby();
    }
  }, [location]);

  const navigateToNearby = async () => {
    if (location) {
      const res = await queryEvents(
        {
          q: "*",
          query_by: "artistName,displayedName",
          sort_by: `coordinates(${location.latitude}, ${location.longitude}):asc,startsAt:asc`,
        },
        location,
      );

      if (!!res?.length) {
        const { allEvents, nearbyEvents, hasMultipleNearbyEvents } =
          checkForNearbyEvents(res, searchDistanceMeters);
        if (hasMultipleNearbyEvents) {
          navigate("/select", {
            replace: false,
            state: { matchedEvents: nearbyEvents },
          });
        } else if (!!allEvents?.length) {
          if (allEvents?.[0]?.version! > 1) {
            //TODO: centralize this legacy routing switch
            navigate(`/e/${allEvents[0]?.id}`);
          } else {
            window.location.href = `/event/${allEvents[0]?.id}`;
          }
        }
      }
      setHasSearched(true);
    }
  };

  // Prepopulate dropdown
  useEffect(() => {
    (async () => {
      if (ipLocation || location) {
        const coords = location ?? ipLocation;
        if (coords) {
          const res = await queryEvents(
            {
              q: "*",
              query_by: "artistName,displayedName",
              sort_by: `coordinates(${coords.latitude}, ${coords.longitude}):asc,startsAt:asc`,
            },
            coords,
          );

          if (!!res?.length) {
            const { allEvents, nearbyEvents, hasMultipleNearbyEvents } =
              checkForNearbyEvents(res, searchDistanceMeters);
            const events = hasMultipleNearbyEvents
              ? nearbyEvents
              : allEvents?.splice(0, 2);
            setPreloadedEvents(events!);
            if (text === null) setText("");
          }
        }
      }
    })();
    return () => {};
  }, [ipLocation, location]);

  useEffect(() => {
    track({ event: "visit" });

    window.scrollTo({ top: 0 });
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#000000");
  }, []);

  const onFindShowClickHandler = () => {
    // logClick({
    //   action: "find_a_show",
    // });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onArtistClickHandler = (path: string) => {
    // logClick({
    //   action: "internal_link_block",
    //   label: path,
    // });
    // history.push(path);
  };

  const onExternalLinkClickHandler = (path: string) => {
    // logClick({
    //   action: "external_link_block",
    //   label: path,
    // });
    window.open(path, "_blank");
  };

  const search = async (searchTerm: string) => {
    setText(searchTerm);
    if (!searchTerm?.length) {
      return Promise.resolve(preloadedEvents as unknown as OptionType[]);
    }
    const queriedEvents = await queryEvents({
      q: searchTerm,
      query_by: "artistName,displayedName,venue",
      limit_hits: 3,
      per_page: 3,
      sort_by: "startsAt:asc",
      filter_by: `&& startsAt:<=${DateTime.utc()
        .plus({ hours: 12 })
        .toMillis()} && startsAt:>=${DateTime.utc()
        .minus({ hours: 12 })
        .toMillis()}`,
    });
    const results = queriedEvents?.map((e) => e.document) || [];
    return results as unknown as OptionType[];
  };

  const onEventSelectClickHandler = (result: any) => {
    // logClick({
    //   action: "select_show_via_search",
    //   label: result?.artistName,
    //   eventId: result?.id,
    // });
    if (result.version > 1) {
      //TODO: centralize this legacy routing switch
      navigate(`/e/${result?.id}`);
    } else {
      window.location.href = `/event/${result?.id}`;
    }
  };

  const isLocationLoading =
    locationLoading &&
    allowLocationSearch &&
    (searchButtonClicked || !!isFirstRoute);
  return (
    <Container>
      <Helmet>
        <title>SET.Live</title>
        <meta
          name="description"
          content="SET.Live is interactive live music. Request a song or just show your support to artists!"
        />
      </Helmet>
      <Content>
        <SearchBox
          isLocationLoading={isLocationLoading}
          onLocationRequest={() => {
            if (!allowLocationSearch) {
              setAllowLocationSearch(true);
            }
            setSearchButtonClicked(true);
            handleLocationRequest(navigateToNearby);
          }}
          locationErrorMessage={locationErrorMessage}
          location={isLocationLoading || !!location}
          noResults={!isLocationLoading && !!location && hasSearched}
        >
          <Autocomplete
            text={text!}
            setText={setText}
            getOptions={search}
            placeholder={placeholderText}
            customOption={({ data }) => {
              const date =
                data?.startsAt > 1 &&
                DateTime.fromMillis(data?.startsAt)?.setLocale(
                  i18n.resolvedLanguage,
                );
              return !!data?.text ? (
                <Item key={data.id}>
                  <P style={{ fontWeight: 400 }}>{t("No matches found...")}</P>
                </Item>
              ) : (
                <Item
                  key={data.id}
                  onClick={() => onEventSelectClickHandler(data)}
                >
                  <P>{data?.displayedName ?? data?.artistName}</P>
                  <Footnote>
                    <span>
                      {data?.venue} •{" "}
                      {date && (
                        <>
                          {date?.toFormat("LLL dd")} • {date?.toFormat("h:mm")}{" "}
                          {date?.toFormat("a")?.toLowerCase()}{" "}
                          {date?.toFormat("ZZZZ")}
                        </>
                      )}
                    </span>
                  </Footnote>
                  <Footnote>{data?.address}</Footnote>
                </Item>
              );
            }}
          />
        </SearchBox>
      </Content>
      <Suspense fallback={null}>
        <BelowFold />
      </Suspense>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 8px;
  margin: 0 auto;
  min-height: 0;
  width: 100%;
  max-width: 600px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: #000000;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;
export const FlexColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Footnote = styled.small`
  color: rgb(34, 34, 34);
  font-family: Poppins, sans-serif;
  font-size: 11px;
  line-height: 14px;
  font-weight: 300;
  font-style: italic;
`;
const Item = styled(FlexColumn)`
  background-color: "#FFFFFF";
  border-bottom: 1px solid ${(props) => props.theme.colors.selectItemDivider};
  color: ${(props) => props.theme.colors.inputText};
  cursor: pointer;
  position: relative;
  padding: 12px;
  ${P} {
    font-weight: 600;
  }
  ${Footnote} {
    color: #4c4c4c;
    font-style: normal;
    line-height: 16px;
  }
  ${Footnote} + ${Footnote} {
    color: #666666;
    font-style: italic;
    font-weight: 500;
  }
  span {
    font-weight: 600;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;
